import { graphql, useStaticQuery,Link } from "gatsby"
import React,{useLayoutEffect,useState} from "react";
import Nav from "../layouts/NavBar";
import moment from 'moment-with-locales-es6';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import "../styles/blog.css";
import NewsLetter from '../components/NewsLetter';
import BaixarPdf from '../components/BaixarPdf';
import Ebook from '../components/Ebook';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Portal } from 'react-portal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLink,
	faPaperPlane,
	faExternalLink
} from '@fortawesome/free-solid-svg-icons';
import {
  faLinkedin,
  faFacebookSquare,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

//import toHtml from 'string-to-html';

/*const getHeading = (html) => {
  //const parser = new DOMParser();
  //const doc = parser.parseFromString(html, 'text/html');
	const doc = toHtml(html);
	const headings = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');
  const headingTree = [];

  // Loop through each heading element
  headings.forEach((heading) => {
    const level = parseInt(heading.tagName.charAt(1));
		
	// Create a new node for the current heading
    const node = { id: heading.innerHTML.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, ''), level:level, title: heading.textContent.trim(), children: [] };

    if (level === 2) {
      // If the heading is a top-level heading, add it to the root of the tree
      headingTree.push(node);
    } else {
      // If the heading is a child heading, find its parent and add it as a child
      let parentNode = headingTree[headingTree.length - 1];
      for (let i = 6; i <= level; i++) {
        const children = parentNode.children;
        parentNode = children[children.length - 1];
      }
      parentNode.children.push(node);
    }
  });

  return headingTree;
} */

const Item = (props) => {
	return (
	<div className="flex flex-col list-none">
			{props.children.map((item, index) => {
				return(
					<div className="mb-2">
						<a href={`#${item.id}`} style={{paddingLeft: `${3*(item.level * 3)}px`}} className="text-gray-500 mt-2 transition duration-150 ease-in-out text-xl hover:text-green-500 hover:font-bold">{item.title}</a>
						<Item id={item.id} level={item.level} title={item.title} children={item.children}></Item>
					</div>
				)	
			})}
	</div>)
};



const Post = ({ data: { artigo,lastPosts,pageContext } }) =>{

	
	var links = [];
	artigo = artigo.Artigos[0];
	//var headings = getHeading(artigo.editor);

	//console.log(headings);

	moment.locale('pt-br');
	var data = moment(artigo.date_created).format('LL');

	if (typeof window !== "undefined") {
		// eslint-disable-next-line global-require
		require("smooth-scroll")('a[href*="#"]')
	}
	
	//const parser = new DOMParser();
	//var doc = parser.parseFromString(artigo.editor, 'text/html'); 
	
	/*if (typeof document !== `undefined`) {
    var div = document.createElement("div");
    div.innerHTML = post.excerpt;
    var descricao = div.innerText;
  } */

  //console.log(post);

  const url = `https://revista.shelfin.com.br/${artigo.slug}`;
 
	if(artigo.readlist != null && artigo.readlist.links != null){
		var links = artigo.readlist.links.split(',');
		var remover = null;

		//console.log("links");
		//console.log(links);
		var links = links.map((link,index)=>{
			//console.log(link);
			var l = link.split('|');
			if(l.join('') == '') return '';

			var ol = l[1].split('/');
			ol = ol[ol.length-1];
			if(ol == artigo.slug) remover = index;
			if(links.length == 1 && ol == artigo.slug ) artigo.readlist = null;
				
			return {titulo:l[0],link:l[1]};
		});
		links.splice(remover,1);
	}
	console.log("links final");
	console.log(links);
  // alert(div.innerText);

  // var co = data.content.html;

  // co = co.match(/<(.*?)>.*?<\/\1>/g);

  // console.log(co);

  // useLayoutEffect(() => {
  //   ReactDOM.render(<BaixarPdf />, document.querySelector('#pdf'));
  // }, []); 

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <SEO 
            title={artigo.title}
            description={artigo.resumo}
			keywords={artigo.keywords}
            imagem={`${process.env.GATSBY_BASE_URL}assets/${artigo.imagem_principal.id}/${artigo.imagem_principal.filename_download}`}
      />
      <Ebook></Ebook>
      <div className="flex flex-col text-gray-800  w-full lg:w-4/5">
        <Nav/>
			
		<div className="w-full grid grid-cols-1 lg:grid-cols-5 gap-4">

			<div className="hidden lg:flex flex-grow">
              <div className="w-full"> 
                <div className="sticky top-2 right-0 w-full">
					<div className="flex w-full justify-end">
						<div className="flex flex-col pr-6">                    
                      <a className="cursor-pointer w-12 h-12  hover:text-blue-500 transition duration-150 ease-in-out flex items-center justify-center text-gray-700 text-2xl">
                      <CopyToClipboard text={url}>
                        <FontAwesomeIcon icon={faLink} />
                      </CopyToClipboard>
                      </a>
                      <a href={'https://www.linkedin.com/shareArticle?mini=true&url='+url} target="_blank" className="w-12 h-12 hover:text-blue-500 transition duration-150 ease-in-out flex items-center justify-center text-gray-700 text-3xl">
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                      <a href={'https://www.facebook.com/sharer/sharer.php?u='+url} target="_blank" className="w-12 h-12 hover:text-blue-500 transition duration-150 ease-in-out flex items-center justify-center text-gray-700 text-3xl">
                        <FontAwesomeIcon icon={faFacebookSquare} />
                      </a>
                      <a href={'https://twitter.com/home?status='+url} target="_blank" className="w-12 h-12  hover:text-blue-500 transition duration-150 ease-in-out flex items-center justify-center text-gray-700 text-3xl">
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                  </div>
					</div> 
                </div>
              </div>
			</div>
			
			<div className="col-span-3 w-full flex flex-col items-center p-4 lg:p-0 justify-center">
                    <div className="flex flex-col w-full mb-6">
                          <Link to="/" className="mb-2 font-medium text-base hover:underline"> 
                            Voltar ao início
                          </Link>
                          <div className="w-full flex flex-col space-y-4">
                            <h1 className="w-full text-5xl font-bold">
                              {artigo.title}
                            </h1>
							<div className="w-full flex flex-wrap">
								{artigo.tags.map(tag =>{ 
									return(<div class="px-2 text-white rounded-md" style={{backgroundColor:'#ff0c9b'}}>{tag}</div>);
								})}
							</div>
                            <div className="">
                              {data}
                            </div>

                            <img src={`${process.env.GATSBY_BASE_URL}assets/${artigo.imagem_principal.id}/${artigo.imagem_principal.filename_download}`}  alt={artigo.imagem_principal.title} className="object-cover w-full h-56 lg:h-100 bg-cover bg-no-repeat bg-blue-400 rounded-lg"/>
                          </div>
							{/*<portal className="w-full my-10" node={document && document.getElementById('table')}>	
							<div className="flex flex-col list-none w-full" >
							{
								true && <div className="flex flex-col space-y-2"> 
									<div className="font-bold text-gray-700 p-3 border-b text-2xl">Conteúdos</div>
									<div className="">
										{headings.map((item, index) => {
											return (<Item id={item.id} level={item.level} title={item.title} children={item.children}></Item>);
										})}
									</div>
								</div>
							}
							</div>
						  </portal> */}	
                    </div>
					
                    <div className="relative flex justify-center items-center w-full">
                      <div className="flex flex-col justify-center items-center w-full">
                          <div className="w-full flex-col">  
                              <div dangerouslySetInnerHTML={{ __html:artigo.editor}}>
                              </div>
		
								<div  className="flex flex-col">
                                  <h2>Dica:</h2>
                                  <p>A <a href="https://shelfin.com.br/use-shelfin/" className="font-bold">Plataforma da Shelfin</a> conta com ferramentas de Avaliação e de Desempenho de colaboradores completamente interativo, voltado para auxiliar na produtividade de sua equipe.</p>
                                  <a href="https://shelfin.com.br/use-shelfin/"><img className="w-full" src="/conheca-a-matriz-de-habilidades-shelfin.png" alt="Conheça a Matriz de Habilidades da Shelfin." /></a>
								</div>
		
								<div className="flex flex-col space-y-2 border-t mt-4 pt-4">
									<div className="w-full text-lg">Gostou do artigo? Compartilhe este conhecimento!</div>
									<div className="flex flex-row space-x-2">                    
										<a className="cursor-pointer w-12 h-12 hover:text-blue-500 transition duration-150 ease-in-out flex items-center justify-center text-gray-700 text-2xl">
											<CopyToClipboard text={url}>
											<FontAwesomeIcon icon={faLink} />
											</CopyToClipboard>
										</a>
										<a href={'https://www.linkedin.com/shareArticle?mini=true&url='+url} target="_blank" className="w-12 h-12  hover:text-blue-500 transition duration-150 ease-in-out flex items-center justify-center text-gray-700 text-3xl">
											<FontAwesomeIcon icon={faLinkedin} />
										</a>
										<a href={'https://www.facebook.com/sharer/sharer.php?u='+url} target="_blank" className="w-12 h-12  hover:text-blue-500 transition duration-150 ease-in-out flex items-center justify-center text-gray-700 text-3xl">
											<FontAwesomeIcon icon={faFacebookSquare} />
										</a>
										<a href={'https://twitter.com/home?status='+url} target="_blank" className="w-12 h-12  hover:text-blue-500 transition duration-150 ease-in-out flex items-center justify-center text-gray-700 text-3xl">
											<FontAwesomeIcon icon={faTwitter} />
										</a>
									</div>
								</div>
	
                          </div>
                      </div>
                    </div>
					
            </div>
			<div className="hidden lg:flex flex-grow">
				
				<div className="w-full h-full">
					<div className="" style={{height:"1050px"}}>
						
					</div>
					{ artigo.readlist != null ? 
							<div className="sticky top-2 bg-indigo-50 rounded-lg p-3 w-full">
								<div className="text-gray-800 flex flex-col space-y-2 w-full">
									<div className="font-bold">{artigo.readlist.titulo}</div>
									<div className="font-light">{artigo.readlist.descricao}</div>
									<div className="flex flex-col space-y-2 text-base">
										{ links != null && links.map(link =>{ 
											return(<a href={link.link} className="group transition duration-150 ease-in-out no-underline flex flex-row items-center space-x-2 hover:text-blue-500 hover:font-bold"><div>{link.titulo}</div> <FontAwesomeIcon className="opacity-0 group-hover:opacity-100" icon={faExternalLink}/> </a>);
										})}
									</div>
								</div>
							</div>
					: <div></div>					
				} 
				</div>
            
			</div>
			
		</div>
		<div className="mt-12">	
			<div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-8 item-center w-full p-10 pl-20 lg:pl-0 px-2 lg:px-32 rounded-lg bg-gray-100">
				<img className="rounded-full object-cover shrink-0 w-44 h-44" src={`${process.env.GATSBY_BASE_URL}assets/${artigo.user_created.avatar.id}`} />
				<div className="flex flex-col space-y-2 min-h-0">
					<div className="flex flex-col">
						<div className="flex flex-col">
							<div className="text-xl font-bold"> Escrito por {artigo.user_created.first_name} {artigo.user_created.last_name}</div>
							{/*<div className="rounded-full w-full bg-blue-500 h-1"></div>*/} 
						</div>
						<div className="text-base font-light">{artigo.user_created.title}</div>
					</div>
					<div className="font-light text-lg w-2/3">{artigo.user_created.description}</div>	
				</div>
			</div>
		</div>
        <div className="w-full h-40"></div>
        <div className="text-2xl mb-8 font-bold px-6 lg:px-0 text-gray-800">
          Talvez você goste:
        </div>
        {/* {console.log(lastPosts)} */}
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8 px-4 lg:px-0">
              {lastPosts.Artigos.map((artigo_next) => {
                moment.locale('pt-br');
                var data = moment(artigo.date_created).format('LL');

                return(
                  <Link to={`/${artigo_next.slug}`}>
                      <div className="group hover:border-green-500 hover:border-4 relative flex flex-col space-y-4 w-full border-4 rounded-lg p-4">
                          <div className="text-gray-800 font-bold text-lg h-20">
                            {artigo_next.title}
                          </div>
                          <div className="group-hover:text-green-500 group-hover:font-bold w-full flex flex-row justify-end">
                            <div>Ler </div>
                          </div>
                      </div>
                  </Link>
                );
              })}
        </div>
      </div> 
      <Footer/>
    </div>
    
  )
}

export default Post;


export const {artigo,lastPosts} = graphql`
  query allArtigos(
    $id: DirectusData_GraphQLStringOrFloat
  ){
    lastPosts: directus {
      Artigos (filter: {id: {_neq: $id}}, limit: 3){
        id
		date_created
		editor
		keywords
		referencias
		resumo
		tags
		title
		imagem_principal {
			id
			description
			title
			filename_download
		}
      }
    }
	artigo: directus {
      Artigos (filter: {id: {_eq: $id}}){
        id
		date_created
		editor
		keywords
		referencias
		resumo
		slug
		tags
		title
		imagem_principal {
			id
			description
			title
			filename_download
		}
		readlist {
			token
			titulo
			descricao
			links
		}
		user_created {
			first_name
			last_name
			description
			avatar {
				id
			}
			title
		}
      }
    }
  }
`
