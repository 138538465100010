import React,{useState} from "react";
import { Link } from "gatsby";

export default function Ebook(){
    const [show, setShow] = useState(true);

    return (
            <div>
                {show == true ? 
                ( <div className="text-white flex items-center justify-center top-0 right-0 fixed w-full min-h-screen bg-green-400">
                    <div className="flex flex-col space-y-4">
                        <div>
                            [EBOOK GRATUITO]
                        </div>
                        <div className="">
                            Descubra o melhor metodo de fazer a gestão de conhecimento de seus colaboradores! 
                        </div>
            
                        <div>
            
                        </div>
                        <button onClick={setShow(false)} className="underline">Não quero desenvolver o conhecimento de meus colaboradores</button>
                    </div>
                </div>
                ) : (<div></div>)}
            </div>
    )
}